

import {
  Component, Prop, Vue,
} from 'vue-property-decorator'
import _ from 'lodash'
import Card from '@/components/atoms/Card.vue'
import SectionTitle from '@/components/atoms/SectionTitle.vue'
import WorkshopCard from '@/components/atoms/WorkshopCard.vue'
import WorkshopContent from '../molecules/WorkshopContent.vue'

interface CuratedContentSectionContent {
  [index: string]: [{size: number, uuid: string}]
}

interface CuratedContentSectionTitle {
  label: string
  link: string
}

/* Example of a curated content section object:
 * {
 *  "content": {
      "film": [
        { "size": 1, "uuid": "88c0840e-ae6c-4808-a547-5f55ec22089f" },
        { "size": 1, "uuid": "88c0840e-ae6c-4808-a547-5f55ec22089f" }
      ]
 *    "article": [
        { "size": 1, "uuid": "88c0840e-ae6c-4808-a547-5f55ec22089f" },
        { "size": 1, "uuid": "88c0840e-ae6c-4808-a547-5f55ec22089f" }
      ],
 *    }
 *  "title": {
 *    "label": "Investigate"
 *    "link" : "/investigate"
 * }
 */
interface CuratedContentSectionObject {
  content: () => CuratedContentSectionContent
  title: () => CuratedContentSectionTitle
}

@Component({
  components: {
    Card,
    SectionTitle,
    WorkshopCard,
    WorkshopContent,
  },
})

/* The CuratedContentSection is mostly similar with the ContentSection
 * but it consumes a slightly different data structure
 * => compare the interfaces of the props of each component
 */
export default class CuratedContentSection extends Vue {
  @Prop({ required: true, type: Object as () => CuratedContentSectionObject })
  private section!: CuratedContentSectionObject

  getObjectKey(obj: object) {
    return Object.keys(obj)
  }
}
