

import {
  Component, Prop, Vue,
} from 'vue-property-decorator'
import _ from 'lodash'
import WorkshopCard from '@/components/atoms/WorkshopCard.vue'


@Component({
  components: {
    WorkshopCard,
  },
})

export default class WorkshopContent extends Vue {
  @Prop({ type: Object, default: () => undefined }) private sectionContent!: object
}
